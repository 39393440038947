.form {
  .g-recaptcha {
    margin-top: 30px;
  }

  .field-validation-error {
    color: get-color("alert");
    font-size: 13px;
    font-family: $secondary-font;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  input[type="number"],
  textarea {
    width: 100%;
    padding: 0 11px;
    margin-bottom: 42px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid get-color("extra-pale-green");
    font-family: $primary-font;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: get-color("dark-green");

    &::placeholder {
      color: inherit;
    }

    &:active,
    &:focus {
      color: get-color("extra-pale-green");
      border-color: get-color("dark-green");
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  input[type="number"] {
    height: 24px;
  }

  textarea {
    padding: 11px;
    min-height: 205px;

    @include breakpoint(small only) {
      min-height: 128px;
    }
  }

  label.error {
    color: #a70000;
    display: block;
    margin-bottom: 0px;
    transform: translateY(-15px);
    font-family: $secondary-font-roman;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
  }

  &__checkbox {
    position: relative;
    text-align: left;

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      top: 0px;
      z-index: 1;

      ~ label {
        position: relative;
        padding-left: 20px;
        padding-top: 0px;
        cursor: pointer;
        line-height: 1;
        display: block;
        font-family: $secondary-font-roman;
        font-size: 10px;
        line-height: 14px;
        color: get-color("dark-green");

        &::before,
        &::after {
          content: "";
          position: absolute;
        }

        &::before {
          top: 0;
          left: 0;
          width: 12px;
          height: 12px;
          border: 1px solid;
          border-color: get-color("dark-green");
          background-color: transparent;
        }

        &::after {
          content: "";
          background-color: get-color("dark-green");
          padding: 3px 0 0 1px;
          top: 2px;
          left: 2px;
          width: 8px;
          height: 8px;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        a {
          color: inherit;
          transition: opacity 0.3s ease-in-out;

          &:hover,
          &:active {
            text-decoration: underline;
          }
        }
      }

      &:checked ~ label {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__submit {
    @include breakpoint(large) {
      text-align: right;
    }

    @include breakpoint(small only) {
      button {
        margin-top: 20px !important;
      }
    }
  }
}
