.savings-calculator {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto 63px;
  padding-top: 100px;
  margin-top: -100px;

  &__inner {
    padding: 21px;

    @include breakpoint(small only) {
      padding: 18px;
    }
  }

  &__form {
    margin-bottom: 42px;

    @include breakpoint(small only) {
      margin-bottom: 36px;
    }

    label,
    input {
      width: 100%;
    }

    label {
      @include subheading();
      color: get-color("mid-green");
      margin-bottom: 10px;
    }
  }

  &__results {
    margin-bottom: 42px;

    @include breakpoint(small only) {
      margin-bottom: 36px;
    }

    &-row {
      padding-bottom: 21px;

      @include breakpoint(small only) {
        padding-bottom: 18px;
      }

      > div.medium-4 {
        text-align: center;

        &:first-of-type {
          text-align: left;
          border-right: 1px solid get-color("pale-green");

          @include breakpoint(small only) {
            border-right: none;
          }
        }
        &:last-of-type {
          border-left: 1px solid get-color("pale-green");

          @include breakpoint(small only) {
            border-left: none;
          }
        }
      }
    }

    h4 {
      @include subheading();
      color: get-color("mid-green");
    }

    p {
      @include subheading;
      color: get-color("mid-green");
    }

    h5 {
      @include body-copy;
      color: get-color("black");
    }
  }

  &__notes {
    margin-bottom: 63px;

    @include breakpoint(small only) {
      padding: 0 18px;
      margi-bottom: 36px;
    }

    h4 {
      @include tertiary-heading;
      color: get-color("mid-green");
    }

    p {
      @include body-copy;
      color: get-color("black");
    }
  }
}
