.home-map {

  margin-top: 150px;
  &__inner {
    position: relative;
  }

  &__map {
    position: relative;

    @include breakpoint(small only) {
      overflow: scroll;
    }

    &-title {
      position: absolute;
      top: -120px;
      width: 100%;
      height: 25%;
      text-align: center;
      z-index: 1;

      @include breakpoint(small only) {
        position: static;
        background: none;
        transform: translate(0, 0);
        padding: 36px 18px;
      }

      h3 {
        @include h2;
        color: #fff;
        margin-bottom: 11px;

      
          color: get-color("mid-green");
     
      }

      h4 {
        @include subheading;
        color: #fff;
        margin-bottom: 0;

     
          color: get-color("mid-green");
      
      }
    }

    &-image {
      min-height: 500px;
      position: relative;

      @include breakpoint(small only) {
        min-height: initial;
      }

      img {
        width: 100%;
        height: auto;

        @include breakpoint(small only) {
          max-width: fit-content;
          object-fit: cover;
        }
      }
    }

    &-points {
      @include breakpoint(small only) {
        display: none;
      }

      &--item {
        position: absolute;
        width: 25px;
        height: 25px;

        > a {
          width: 25px;
          height: 25px;
          padding: 1px 8px;
          border: 1px solid get-color("dark-green");
          font-family: $primary-font;
          color: get-color("dark-green");
          background-color: get-color("orange");
          font-size: 17px;
          line-height: 1;
          font-weight: 700;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: get-color("dark-green");
            color: #fff;
          }
        }
      }

      &-info {
        display: none;
        position: relative;
        transform: translate(-25%, -50%);
        background-color: get-color("green-hint");
        padding: 21px;
        min-width: 300px;
        min-height: 270px;
        z-index: 1;
        border: 1px solid get-color("dark-green");

        @include breakpoint(small only) {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &.left {
          transform: translate(calc(-100% + 27px), -50%);

          @include breakpoint(small only) {
            transform: translate(-50%, -50%);
          }
        }

        &.open {
          display: block;
        }

        &-close {
          position: absolute;
          top: 4px;
          right: 4px;
          width: 24px;
          height: 24px;
          background: url("img/close-mid-green.svg") no-repeat;
          background-size: 24px 24px;
          cursor: pointer;
          text-indent: -12345px;
          transition: all 0.3s ease-in-out;
          transform: rotate(45deg);

          &:focus,
          &:active {
            outline: none;
          }

          &:hover {
            transform: rotate(135deg);
          }
        }

        h4 {
          width: 25px;
          height: 25px;
          border: 1px solid #fff;
          font-family: $primary-font;
          color: get-color("dark-green");
          background-color: get-color("orange");
          font-size: 17px;
          line-height: 1;
          font-weight: 700;
          padding-top: 3px;
          padding-left: 8px;
          margin-bottom: 18px;
        }

        h3 {
          @include tertiary-heading;
          color: get-color("mid-green");
          margin-bottom: 21px;
        }

        p {
          @include body-copy;
          color: get-color("dark-green");
          margin-bottom: 21px;
        }

        .multiple-buttons {
          .button {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
