.menus {
  width: 100%;
  max-width: 1344px;
  margin: 0 auto 126px;
  padding: 0 42px;

  @include breakpoint(small only) {
    padding: 0;
    margin-bottom: 72px;
  }

  &__intro {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0 auto 42px;
    max-width: 496px;
    padding: 42px 21px 39px;
    text-align: center;
    width: 100%;

    @include breakpoint(small only) {
      margin-bottom: 36px;
      padding: 36px 18px 27px;
    }

    &-inner {
      h3 {
        @include subheading();
        color: get-color("mid-green");
        margin-bottom: 21px;
      }

      p {
        @include body-copy();
      }

      a {
  
        color: inherit;
      }
    }
  }

  &__inner {
    padding: 18px;
    background-color: get-color("green-hint");

    @include breakpoint(medium) {
      padding: 76px 50px;
    }

    h2 {
      @include tertiary-heading();
      color: get-color("mid-green");
    }

    h3 {
      @include tertiary-heading();
      color: get-color("mid-green");
    }

    p,
    li {
      @include body-copy();
      line-height: 3;
      color: get-color("dark-green");
    }

    span {
      text-transform: uppercase;
      font-family: $secondary-font;
      font-weight: 600;
      font-size: 11px;
      text-transform: uppercase;
      color: get-color("dark-green");
    }
  }

  &__image {
    position: relative;
    min-height: 300px;

    picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @include breakpoint(medium) {
        width: calc(100% - 40px);
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__heading {
    h2 {
      font-family: $primary-font;
      font-weight: 700;
      margin-bottom: 18px;
      font-size: 30px;
      line-height: 24px;
      margin-bottom: 21px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(small only) {
      padding: 24px 0;
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    .button {
      background-color: get-color('green-hint');
      padding: 15px 15px;

      a {
        text-decoration: none;
      }
      
      &:hover {
        color: white;
        background-color: get-color('mid-green');
      }
    }
  }
  &__list {
    margin: 0 0 42px;
    list-style-type: none;

    @include breakpoint(small only) {
      margin-bottom: 27px;
    }

    li {
      position: relative;
      padding-right: 42px;

      span {
        position: absolute;
        top: 3px;
        right: 0;
        color: get-color("dark-green");
      }
    }

    a {
      color: get-color("dark-green");
    }
  }

  &__times {
    h3 {
      @include price;
      font-size: 30px;
      line-height: 24px;
      margin-bottom: 22px;
    }

    ul {
      margin: 0 0 42px;
      list-style-type: none;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-content {
      max-width: 300px;
      width: 100%;

      @include breakpoint(small only) {
        max-width: none;
      }
    }
  }
}
