.logo-carousel {
  text-align: center;
  background-color: #fff;

  &__inner {
    max-width: 1344px;
    width: 100%;
    margin: 0 auto;
    padding: 21px 42px;
    position: relative;

    @include breakpoint(small only) {
      padding: 18px 54px;
    }

    .slick-arrow {
      position: absolute;
      z-index: 100;
      top: 50%;
      width: 21px;
      height: 21px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      text-indent: -12345px;
      background: no-repeat;
      background-size: 27px 21px;
      transform: translateY(-50%);

      &:hover,
      &:active {
        width: 27px;
      }

      &.slick-prev {
        left: 21px;
        background-image: url("img/arrow-green-prev.svg");
        background-position: left center;
      }

      &.slick-next {
        right: 21px;
        background-image: url("img/arrow-green-next.svg");
        background-position: right center;
      }
    }
  }

  &__logo {
    padding: 0 10.5px;

    @include breakpoint(small only) {
      padding: 9px;
    }

    img {
      width: 100%;
    }
  }

  &--small {
    margin-bottom: 42px;

    @include breakpoint(small only) {
      margin-bottom: 36px;
    }

    .logo-carousel {
      &__inner {
        max-width: 840px;

        @include breakpoint(small only) {
          max-width: 640px;
          padding: 0 54px;
        }

        .slick-arrow {
          &.slick-prev {
            @include breakpoint(small only) {
              left: 18px;
            }
          }

          &.slick-next {
            @include breakpoint(small only) {
              right: 18px;
            }
          }
        }
      }
    }
  }
}
