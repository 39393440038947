.other-rooms {
  width: 100%;
  max-width: 1125px;
  margin: 0 auto 120px;
  padding: 0 21px;

  @include breakpoint(small only) {
    padding: 0 27px;
    margin-bottom: 36px;
  }

  h2 {
    @include h2;
    color: get-color("mid-green");
    margin-bottom: 45px;
    text-align: center;
  }

  &__room {
    padding: 0 25px 0;
    transition: all 0.3s ease-in-out;
  }

  &__image {
    margin-bottom: 0;
  }

  &__content {
    padding: 27px 0 21px;
    background-color: transparent;

    h3 {
      @include tertiary-heading;
      color: get-color("mid-green");
      margin-bottom: 0;

      a {
        color: inherit;
      }
    }

    .multiple-buttons {
      margin: 0;
    }
  }

  &__rate {
    @include price;
    color: get-color("mid-green");
    margin-bottom: 42px;
    padding-top: 21px;
  }

  .slick-arrow {
    position: absolute;
    z-index: 100;
    top: 240px;
    width: 21px;
    height: 21px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-indent: -12345px;
    background: no-repeat;
    background-size: 27px 21px;

    @include breakpoint(small only) {
      top: 155px;
    }

    &:hover,
    &:active {
      width: 27px;
    }

    &.slick-prev {
      left: -15px;
      background-image: url("img/arrow-green-prev.svg");
      background-position: left center;
    }

    &.slick-next {
      right: -15px;
      background-image: url("img/arrow-green-next.svg");
      background-position: right center;
    }
  }

  &__carousel.one-room {
    max-width: 400px;
    margin: auto;
  }

  &__carousel.two-rooms {
    max-width: 750px;
    margin: auto;
  }

  &__carousel.center-mode {
    .other-rooms {
      &__room {
        @include breakpoint(large) {
          padding-top: 75px;
        }
      }

      &__content .multiple-buttons {
        @include breakpoint(large) {
          display: none;
        }
      }

      &__rate {
        @include breakpoint(large) {
          display: none;
        }
      }
    }

    .slick-current .other-rooms {
      &__room {
        @include breakpoint(large) {
          padding-top: 0;
        }
      }

      &__content .multiple-buttons {
        @include breakpoint(large) {
          display: block;
        }
      }

      &__rate {
        @include breakpoint(large) {
          display: block;
        }
      }
    }
  }
}
