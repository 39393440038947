.offers-detail {
  a {
    color: #00271e;
  }
  h1 {
    @include page-heading();
    color: get-color("mid-green");
  }
  width: 100%;
  max-width: 1260px;
  margin: 0 auto 80px;
  padding-top: 50px;

  &__intro {
    text-align: left;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    max-width: none;

    h2 {
      @include subheading();
      color: get-color("mid-green");
    }

    @include breakpoint(small only) {
      margin-bottom: 80px;
    }

    @include breakpoint(medium down) {
      padding: 0 20px;
    }

    @include breakpoint(medium) {
      padding-right: 50px;
    }

    @include breakpoint(large) {
      margin-left: 8.33333%;
    }

    .multiple-buttons {
      text-align: left;
    }
  }

  &__price {
    @include price();
    padding-top: 10px;
    color: get-color("mid-green");
  }

  &__details {
    @include breakpoint(small only) {
      padding: 0 18px;
    }

    &-inner {
      padding: 42px 21px;
      background-color: get-color("green-hint");

      @include breakpoint(large) {
        padding: 32px 42px;
      }

      &.empty {
        padding: 0;
      }
    }

    h2 {
      @include tertiary-heading();
      margin-bottom: 20px;
      color: get-color("mid-green");
    }

    p {
      @include body-copy();
      color: get-color("dark-green");
    }

    ul {
      margin: 0;
      list-style: none;
    }

    li {
      @include bullet-list();
      margin-bottom: 0;
      color: get-color("dark-green");
      font-weight: 600;
      padding-left: 12px;
      position: relative;
      font-size: 13px;
      margin-bottom: 15px;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 2px;
        background-color: get-color("mid-green");
        transform: translateY(-50%);
      }
    }
  }
}
