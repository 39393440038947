.booking {
  background-color: get-color("dark-green");

  &__wrap {
    min-height: 100%;
    padding: 105px 42px;

    @include breakpoint(medium only) {
      padding: 105px 21px;
    }

    @include breakpoint(small only) {
      padding: 18px 18px 54px;
    }
  }

  &__inner {
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    @include breakpoint(small only) {
      padding: 0;
    }
  }

  .off-canvas__close {
    top: -42px;

    @include breakpoint(small only) {
      top: 0;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 24px;

    @include breakpoint(small only) {
      margin-top: 50px;
    }

    h2 {
      @include tertiary-heading;
      color: #fff;
      margin-bottom: 21px;
    }
  }

  &__tabs {
    @include breakpoint(small only) {
      margin-bottom: 18px;
    }
    .tabs {
      background-color: transparent;
      border: none;
      display: flex;
      gap: 42px;
      justify-content: space-evenly;
      margin: 0 auto 42px;

      @include breakpoint(medium only) {
        gap: 21px;
      }

      @include breakpoint(small only) {
        flex-direction: column;
        margin-bottom: 0;
        gap: 0;
      }

      &::before,
      &::after {
        content: none;
      }

      &-title {
        flex-grow: 0;
        width: 33%;
        text-align: center;

        @include breakpoint(small only) {
          margin-bottom: 18px;
          width: 75%;
          margin: 0 auto 18px;
        }

        a {
          font-family: $primary-font;
          color: #fff;
          font-size: 15px;
          line-height: 19px;
          font-weight: 700;
          padding: 10px 7px;
          border: 1px solid #fff;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: get-color("orange");
            color: get-color("dark-green");
          }
        }

        &.is-active {
          a {
            background-color: get-color("orange");
            color: get-color("dark-green");
          }
        }
      }
    }

    &-content {
      text-align: center;

      h3 {
        @include subheading;
        color: #fff;
        margin-bottom: 42px;
      }

      h5 {
        @include body-copy;
        color: #fff;
        margin-bottom: 21px;

        @include breakpoint(small only) {
          padding: 0 45px;
        }
      }

      &-inner {
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto 68px;
        gap: 42px;

        @include breakpoint(medium only) {
          gap: 21px;
        }

        @include breakpoint(small only) {
          flex-direction: column;
          gap: 0;
          margin-bottom: 18px;
          padding: 0 36px;
        }
      }

      &-item {
        text-align: center;
        position: relative;
        max-width: 33%;
        max-width: 252px;

        @include breakpoint(small only) {
          margin-bottom: 18px;
          max-width: 100%;
        }

        &-text {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          padding: 10px;
          transform: translateY(-50%);

          @include breakpoint(medium only) {
            padding: 7px;
          }

          h4 {
            @include tertiary-heading;
            color: #fff;
            margin-bottom: 21px;
          }

          p {
            @include body-copy;
            color: #fff;
            margin-bottom: 0;
          }
        }

        &-image {
          img {
          }

          &--overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.45);
            transition: 0.3s ease-in-out;
          }
        }

        a {
          &:hover {
            .booking__tabs-content-item-image--overlay {
              opacity: 1;

              @include breakpoint(small only) {
                opacity: 0.26;
              }
            }
          }
        }
      }
    }
  }
}

.tabs-panel {
  padding: 0 !important;
}
