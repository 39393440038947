.blog-detail {
  width: 100%;
  max-width: 1260px;
  margin: 47px auto 60px;

  h1 {
    @include page-heading();
    color: get-color("mid-green");
  }

  h2 {
    @include subheading();
    color: get-color("mid-green");
  }

  h3 {
    @include subheading();
    color: get-color("mid-green");
    margin-bottom: 21px;
  }

  @include breakpoint(large) {
    margin-bottom: 100px;
  }

  &__main {
    @include breakpoint(small only) {
      text-align: center;
      padding-right: 20px;
      margin-bottom: 81px;
    }

    @include breakpoint(medium down) {
      padding-left: 20px;
    }

    @include breakpoint(medium) {
      padding-right: 60px;
    }

    .multiple-buttons {
      margin-top: 30px;
    }
  }

  .intro {
    margin-bottom: 0;
    max-width: none;
    padding: 0;

    @include breakpoint(medium) {
      text-align: left;
    }
  }

  &__post-date {
    @include subheading();
    color: get-color("mid-green");
  }

  &__post-categories {
    margin: 0 0 30px;
    list-style-type: none;
    @include subheading();
    color: get-color("mid-green");

    li {
      display: inline;

      &::after {
        content: ", ";
      }

      &:last-child::after {
        content: "";
      }
    }

    a {
      color: inherit;
    }
  }

  &__content {
    margin-top: 40px;

    p {
      @include body-copy();
      color: get-color("dark-green");

      a {
        color: inherit;
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }

    li {
      @include body-copy();
      color: get-color("dark-green");
    }

    p img {
      width: 100% !important;
      max-width: 100% !important;
      height: auto !important;
    }
  }

  &__sidebar {
    &-inner {
      padding: 40px 20px;
      background-color: get-color("green-hint");

      @include breakpoint(large) {
        padding: 40px;
      }
    }

    h3 {
      @include tertiary-heading;
      color: get-color("mid-green");
      margin-bottom: 42px;
    }

    span {
      font-family: $primary-font;
      color: get-color("dark-green");
      font-size: 15px;
      line-height: 19px;
      font-weight: 700;
    }

    ul {
      margin: 0;
      list-style-type: none;
    }

    li {
      margin-bottom: 36px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      margin-bottom: 12px;
    }
  }

  table {
    tr,
    td {
      @include body-copy();
    }
  }

  &__cta {
    .button img {
      bottom: -14px;
    }
  }
}
