.pagination {
  width: 100%;
  max-width: 1344px;
  margin: 0 auto 63px;
  text-align: center;
  padding: 0 42px 126px;
  text-transform: uppercase;

  @include breakpoint(small only) {
    padding: 0 0 72px;
  }

  a {
    font-size: 10px;
    font-family: $secondary-font;
    color: get-color("dark-green");

    &:hover {
      background-color: transparent;
    }
  }

  &-previous {
    a {
      position: relative;
      display: inline-block;
      height: 21px;
      line-height: 21px;

      &::before {
        display: none;
      }

      &::after {
        content: "";
        position: absolute;
        top: 3px;
        width: 21px;
        height: 21px;
        background: no-repeat;
        background-size: 27px 21px;
        transition: all 0.3s ease-in-out;
      }

      &:hover,
      &:active {
        &::after {
          width: 27px;
          background-image: url("img/arrow-green-prev.svg");
        }
      }
    }
  }
  &-next {
    a {
      position: relative;
      display: inline-block;
      height: 21px;
      line-height: 21px;

      &::before {
        display: none;
      }

      &::after {
        content: "";
        position: absolute;
        top: 4px;
        width: 21px;
        height: 21px;
        background: no-repeat;
        background-size: 27px 21px;
        transition: all 0.3s ease-in-out;
      }

      &:hover,
      &:active {
        &::after {
          width: 27px;
          background-image: url("img/arrow-green-next.svg");
        }
      }
    }
  }

  &-previous {
    float: left;

    a {
      padding-left: 37px;
      margin-left: 20px;

      @include breakpoint(large) {
        margin-left: 70px;
      }

      &::after {
        left: 0;
        background-image: url("img/carousel-prev.svg");
        background-position: left center;
      }
    }
  }

  &-next {
    float: right;

    a {
      padding-right: 37px;
      margin-right: 20px;

      @include breakpoint(large) {
        margin-right: 70px;
      }

      &::after {
        right: 0;
        background-image: url("img/carousel-next.svg");
        background-position: right center;
      }
    }
  }
}
