.blog-slider {
  max-width: 1296px;
  width: 100%;
  margin: 0 auto 105px;
  padding: 0 18px;

  @include breakpoint(small only) {
    margin-bottom: 90px;
  }

  &__inner {
    .slick-arrow {
      position: absolute;
      z-index: 100;
      top: 50%;
      transform: translateY(-50%);
      width: 21px;
      height: 21px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      text-indent: -12345px;
      background: no-repeat;
      background-size: 27px 21px;

      @include breakpoint(small only) {
        top: auto;
        bottom: -36px;
        transform: translateY(0%);
      }

      &:hover,
      &:active {
        width: 27px;
      }

      &.slick-prev {
        left: -15px;
        background-image: url("img/arrow-green-prev.svg");
        background-position: left center;

        @include breakpoint(medium only) {
          left: -5px;
        }

        @include breakpoint(small only) {
          left: calc(50% - 54px);
        }
      }

      &.slick-next {
        right: -15px;
        background-image: url("img/arrow-green-next.svg");
        background-position: right center;

        @include breakpoint(medium only) {
          right: -5px;
        }

        @include breakpoint(small only) {
          right: calc(50% - 54px);
        }
      }
    }
  }

  &__slide {
    padding: 0 21px;

    @include breakpoint(small only) {
      padding: 0;
    }

    &-wrap {
      position: relative;
    }

    &-image {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(get-color("dark-green"), 0.37);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        @include tertiary-heading;
        font-weight: 700;
        color: #fff;
        margin-bottom: 0;
        text-align: center;
        margin-bottom: 30px;
      }

      h5 {
        font-family: $primary-font;
        font-size: 15px;
        line-height: 19px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 0;
      }
    }
  }
}
