.standard-content {
  width: 100%;
  max-width: 1290px;
  margin: 0 auto 120px;
  padding: 0 15px;
  text-align: left;

  @include breakpoint(small only) {
    margin-bottom: 54px;
  }

  img {
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
  }

  h2 {
    @include h2;
    color: get-color("mid-green");
    margin-bottom: 21px;

    @include breakpoint(small only) {
      margin-bottom: 18px;
    }
  }

  p {
    @include body-copy();
    color: get-color("dark-green");
    text-align: left;

    a {
      text-decoration: underline;
      color: inherit;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  ul,
  ol {
    margin: 0 0 42px;
    list-style: none;
  }

  li {
    font-family: $secondary-font;
    font-size: 13px;
    font-weight: 600;
    color: get-color("dark-green");
    margin-bottom: 11px;
    padding-left: 10px;
    position: relative;

    a {
      color: inherit;
      text-decoration: underline;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 4px;
      height: 2px;
      background-color: get-color("dark-green");
      transform: translateY(-50%);
    }
  }

  &__cta {
    text-align: left;
    padding-top: 42px;
  }

  &__full-width {
    margin: auto;

    p {
      text-align: center !important;
    }
  }
}
