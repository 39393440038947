.landing {
  margin: 0 auto;
  max-width: 1260px;
  padding: 105px 0 126px;
  width: 100%;

  @include breakpoint(small only) {
    padding: 36px 0;
  }

  &__slider,
  &__content {
    transition: transform cubic-bezier(0.5, 0.01, 0.3, 1.01),
      opacity cubic-bezier(0.5, 0.01, 0.3, 1.01);
    will-change: transform, opacity;
    backface-visibility: hidden;
    transition-duration: 1250ms;
    opacity: 0;
  }

  &__item {
    margin-bottom: 120px;

    @include breakpoint(small only) {
      margin-bottom: 18px;
    }

    &:nth-child(odd) {
      .landing__slider {
        transform: translateX(100px);

        @include breakpoint(medium) {
          order: 2;
          margin-left: 0;
        }

        &.landing-alternating-fade-in {
          transform: translateX(0);
          opacity: 1;
        }
      }

      .landing__content {
        transform: translateX(-100px);

        @include breakpoint(medium) {
          order: 1;
        }

        @include breakpoint(large) {
          margin-left: 8.33333%;
        }

        &.landing-alternating-fade-in {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    &:nth-child(even) {
      .landing__slider {
        transform: translateX(-100px);

        &.landing-alternating-fade-in {
          transform: translateX(0);
          opacity: 1;
        }
      }

      .landing__content {
        transform: translateX(100px);

        &.landing-alternating-fade-in {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }

  &__slider {
    position: relative;
    min-height: 300px;

    @include breakpoint(large) {
      min-height: 425px;
    }

    &-inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div {
      height: 100%;
    }

    &-image {
      background: no-repeat center center;
      background-size: cover;

      a,
      picture {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .reform-slider-arrows {
      display: none;
    }
  }

  &__content {
    padding: 60px 5%;
    @include flex-align-center();
    text-align: left;
    z-index: 1;

    @include breakpoint(small only) {
      padding: 27px 18px 18px;
    }

    h3 {
      @include h2;
      color: get-color("mid-green");
      margin-bottom: 21px;

      a {
        color: inherit;
      }
    }

    h4 {
      @include subheading();
      color: get-color("mid-green");
      margin-bottom: 21px;

      a {
        color: inherit;
      }
    }

    p {
      @include body-copy();
      color: get-color("dark-green");
      margin-bottom: 21px;

      @include breakpoint(small only) {
        margin-bottom: 18px;
      }
    }

    ul {
      margin: 0 0 32px;
      list-style: none;
    }

    li {
      font-family: $secondary-font;
      font-size: 13px;
      font-weight: 600;
      color: get-color("dark-green");
      margin-bottom: 11px;
      padding-left: 10px;
      position: relative;

      a {
        color: inherit;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 2px;
        background-color: get-color("dark-green");
        transform: translateY(-50%);
      }
    }

    .multiple-buttons {
      padding-top: 21px;

      @include breakpoint(small only) {
        padding-top: 18px;
      }

      .button {
        margin-bottom: 0;
      }
    }
  }
}
