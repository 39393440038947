.blog-landing {
  max-width: 1344px;
  margin: 0 auto;
  width: 100%;
  padding: 0 42px;

  @include breakpoint(small only) {
    padding: 0;
  }

  &__item {
    margin-bottom: 42px;

    @include breakpoint(small only) {
      margin-bottom: 18px;
    }

    &:not(.blog-landing__item--highlighted) {
      @include breakpoint(medium) {
        padding-right: 10.5px;
        padding-left: 10.5px;
      }
    }

    &-wrap {
      @include breakpoint(medium) {
        margin: 0 -10.5px;
      }
    }
  }

  &__image {
    position: relative;
    min-height: 340px;
    background: no-repeat center center;
    background-size: cover;

    a,
    picture {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__content {
    padding: 18px;
    @include flex-align-center;
    background-color: get-color("green-hint");
    text-align: left;

    @include breakpoint(medium) {
      padding: 21px 0;

      &:is(.blog-landing__item--highlighted .blog-landing__content) {
        padding: 21px;
      }
    }

    @include breakpoint(large) {
      &:is(.blog-landing__item--highlighted .blog-landing__content) {
        padding: 42px;
      }
    }

    h2 {
      @include tertiary-heading;
      color: get-color("mid-green");
      margin-bottom: 21px;

      @include breakpoint(small only) {
        text-align: left;
        margin-bottom: 18px;
      }

      a {
        color: inherit;
      }
    }

    h3 {
      @include subheading;
      color: get-color("mid-green");
      margin-bottom: 21px;

      @include breakpoint(small only) {
        margin-bottom: 18px;
      }

      &:empty {
        margin-bottom: 0;
      }
    }

    p {
      @include body-copy();

      @include breakpoint(small only) {
        text-align: left;
      }
    }
  }

  &__post-date {
    @include subheading();
    color: get-color("mid-green");
    margin-bottom: 13px;

    @include breakpoint(small only) {
      text-align: left;
    }
  }

  &__post-categories {
    margin-left: 0;
    list-style-type: none;
    @include subheading();
    color: get-color("mid-green");
    margin-bottom: 10px;

    @include breakpoint(small only) {
      text-align: left;
    }

    li {
      display: inline;

      &::after {
        content: ", ";
      }

      &:last-child::after {
        content: "";
      }
    }

    a {
      color: inherit;
    }
  }

  &__cta {
    padding-top: 14px;

    @include breakpoint(small only) {
      text-align: left;
    }

    &:is(.blog-landing__item--highlighted .blog-landing__cta) {
      padding-top: 42px;
    }

    .button {
      margin-bottom: 0;
    }
  }

  .filter {
    margin-bottom: 32px;

    &__inner {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
}

.blog {
  background-color: get-color("green-hint");
  margin-top: -20px;
  margin-bottom: -60px;
}
