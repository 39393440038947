.history {
  position: relative;

  &__item {
    background-color: get-color("mid-green");
  }

  &__image {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    padding: 0 42px 21px 42px;

    @include breakpoint(small only) {
      padding: 36px 18px 126px;
    }

    &-inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      padding-top: 42px;

      @include breakpoint(small only) {
        padding-top: 0;
      }
    }
  }

  &__date {
    @include h2();
    color: get-color("pale-green");
    margin-bottom: 42px;

    @include breakpoint(small only) {
      padding-left: 54px;
      margin-bottom: 36px;
    }
  }

  h2 {
    color: #fff;
    font-size: 85px;
    font-family: $primary-font;
    line-height: 84px;
    margin-bottom: 42px;

    @include breakpoint(small only) {
      position: initial;
      left: auto;
      font-size: 60px;
      line-height: 62px;
      margin-bottom: 36px;
    }
  }

  &__text {
    padding: 0 calc(8% + 84px) 0 0;

    @include breakpoint(small only) {
      padding-left: 54px;
      padding-right: 36px;
    }

    p {
      @include body-copy();
      color: #fff;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include breakpoint(small only) {
      margin-top: 30px;
    }
  }

  &__mobile-nav {
    &--prev,
    &--next {
      position: absolute;
      bottom: 19px;
      width: calc(50% - 40px);
      font-family: $primary-font;
      font-size: 30px;
      line-height: 1;
      font-weight: 700;
      color: #fff;
      pointer-events: none;
    }

    &--prev {
      left: 63px;

      @include breakpoint(small only) {
        left: 50px;
      }
    }

    &--next {
      right: 63px;
      text-align: right;

      @include breakpoint(small only) {
        right: 50px;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    bottom: 21px;
    text-indent: -12345px;
    cursor: pointer;

    &.slick-prev {
      left: 31px;
      background: url("img/carousel-prev-white.svg") no-repeat;
      width: 21px;
      height: 21px;
      background-size: 21px 21px;
      background-position: left center;
      transition: all 0.3s ease-in-out;
      z-index: 100;

      &:hover,
      &:active {
        width: 27px;
        background: url("img/carousel-prev-pink.svg") no-repeat;
        background-size: 27px 21px;
      }

      @include breakpoint(small only) {
        left: 22px;
      }
    }

    &.slick-next {
      right: 31px;
      background: url("img/carousel-next-white.svg") no-repeat;
      width: 21px;
      height: 21px;
      background-size: 21px 21px;
      background-position: right center;
      transition: all 0.3s ease-in-out;

      &:hover,
      &:active {
        background: url("img/carousel-next-pink.svg") no-repeat;
        width: 27px;
        background-size: 27px 21px;
      }

      @include breakpoint(small only) {
        right: 22px;
      }
    }
  }
}
