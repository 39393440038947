.off-canvas {
  z-index: 50000;
  width: 100%;
  transform: translateX(-100%);
  background: url("img/burger-contour-lines.svg") no-repeat;
  background-size: cover;
  background-color: get-color("dark-green");

  &.is-open {
    transform: translateX(0);
  }

  &__inner {
    padding: 20px;
    min-height: calc(100vh - 166px);

    @include breakpoint(large) {
      padding: 35px 53px;
    }
  }

  &__header {
    position: relative;
    margin-bottom: 84px;

    @include breakpoint(small only) {
      margin-bottom: 17px;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    left: 0;
    width: 27px;
    height: 27px;
    background: url("img/close-white.svg") no-repeat;
    background-size: 27px 27px;
    cursor: pointer;
    text-indent: -12345px;
    transition: all 0.3s ease-in-out;

    &:focus,
    &:active {
      outline: none;
    }

    &:hover {
      transform: rotate(180deg);
    }
  }

  &__logo {
    width: 151px;
    margin: 0 auto;
  }

  &__nav-primary {
    margin-bottom: 65px;

    a {
      transition: color 0.3s ease-in-out;

      &:hover,
      &:active {
        color: get-color("pink");
      }
    }

    > ul {
      margin: 0;
      list-style-type: none;

      > li {
        margin-bottom: 50px;

        > a {
          @include tertiary-heading;
          color: #fff;
        }

        &.open > a {
          color: get-color("pink");
        }

        > ul {
          margin: 8px 0 30px 35px;
          list-style-type: none;
          display: none;

          @include breakpoint(small only) {
            margin-left: 0;
          }

          > li {
            margin-bottom: 32px;

            @include breakpoint(small only) {
              line-height: 1;
              margin-bottom: 20px;
            }

            > a {
              @include tertiary-heading;
              color: #fff;
              font-size: 22px !important;
              line-height: 24px !important;

              &:hover {
                color: get-color("pink");
              }
            }
          }
        }
      }
    }
  }

  &__sidebar {
    @include breakpoint(small only) {
      margin-bottom: 54px;
    }
  }

  &__offer {
    border: 1px solid #828e68;
    text-align: center;

    &-inner {
      background-color: get-color("dark-green");

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: 21px;
      }

      h2 {
        @include tertiary-heading;
        color: get-color("mid-green");
        margin-bottom: 21px;
        padding: 0 5px;
      }
    }

    &-price {
      @include price;
      color: get-color("mid-green");
      margin-bottom: 21px;
      padding: 0 5px;
    }

    &-cta {
      display: flex;
      justify-content: space-evenly;
    }
  }

  .footer__newsletter {
    max-width: none;
    background-color: get-color("mid-green");
    padding: 52px 21px 42px;

    @include breakpoint(small only) {
      text-align: center;
    }
  }
}
