.standard-heading {
	width: 100%;
	max-width: 1025px;
	margin: 0 auto;
	padding: 0 15px;

	h2 {
		@include h2();
		color: get-color('mid-green') !important;
		margin-bottom: 36px;

		a {
			color: inherit;
		}
	}

	ul {
		margin: 0 0 32px;
		list-style: none;
	}

	li {
		font-family: $secondary-font;
		font-size: 13px;
		font-weight: 600;
		color: get-color('dark-green');
		margin-bottom: 11px;
		padding-left: 10px;
		position: relative;

		a {
			color: inherit;
			text-decoration: underline;
		}

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 4px;
			height: 2px;
			background-color: get-color('dark-green');
			transform: translateY(-50%);
		}
	}

	p {
		@include body-copy;
		color: get-color('dark-green');

		a {
			color: inherit;
			text-decoration: underline;
		}
	}



	&__item {
		display: flex;
		max-width: 1155px;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 37px;

		@include breakpoint(small only) {
			margin-bottom: 0;
		}
	}

	&__content {
		&-inner {
			padding: 50px;

			@include breakpoint(small only) {
				padding: 36px 0;
			}
		}
	}


	&__item--image-right {
		.standard-heading {
			&__image {
				@include breakpoint(medium) {
					order: 2;
				}

				&-small {
					@include breakpoint(small only) {
						right: initial;
						left: 20px;
					}

					@include breakpoint(medium) {
						right: initial;
						left: 0;
						transform: translate(-50%, -50%);
					}
				}
			}

			&__content {
				@include breakpoint(medium) {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					order: 1;
					padding-left: 20px;
				}

				@include breakpoint(large) {

				}
			}
		}
	}

	.multiple-buttons {
		margin-top: 32px;
	}
}
