.stay {
  width: 100%;

  &__item {
    padding: 84px 0;

    @include breakpoint(small only) {
      padding: 0;
    }

    &.white {
      background-color: #fff;

      h2 {
        color: get-color("pink");
      }
    }

    &--wide {
      padding: 84px 42px;

      @include breakpoint(small only) {
        padding: 0;
      }

      .stay__text {
        padding-right: 0;

        @include breakpoint(small only) {
          padding-right: 18px;
        }
      }
    }
  }

  &__image {
    @include breakpoint(small only) {
      order: 1;
      padding-left: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    position: relative;
    padding-right: 4%;
    padding-left: 42px;
    padding-top: 105px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(small only) {
      order: 2;
      padding: 27px 18px 36px;
    }

    &-title {
    }

    &-main {
    }

    h2 {
      @include super-header;
      color: get-color("mid-green");
      margin-bottom: 0;

      @include breakpoint(small only) {
        position: initial;
        top: auto;
        left: auto;
        margin-bottom: 18px;
      }
    }

    h3 {
      @include subheading();
      margin-bottom: 21px;
      color: get-color("mid-green");

      @include breakpoint(small only) {
        margin-bottom: 18px;
      }
    }

    p {
      @include body-copy;
      color: get-color("dark-green");
    }

    .multiple-buttons {
      a.button {
        margin-bottom: 0;
      }
    }
  }

  &__text {
    .stay__content {
      p {
        max-width: 315px;
        width: 100%;

        @include breakpoint(small only) {
          max-width: none;
        }
      }
    }
  }
}
