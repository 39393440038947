.parallax-contour {
  height: 84px;
  background-image: url('img/parallax-contours.svg');
  background-color: get-color('green-hint');
	background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  @include breakpoint(small only) {
    height: 54px;
  }
}

body.template-home {
  
  .parallax-contour {
    height: 147px;

    @include breakpoint(small only) {
      height: 54px;
    }
  }
}