.accommodation {
  width: 100%;
  max-width: 1045px;
  margin: 0 auto;

  &__items {
  }

  &__item {
    padding: 0 21px;
    margin-bottom: 105px;

    @include breakpoint(small only) {
      margin-bottom: 54px;
    }

    &-inner {
    }
  }

  &__image {
    margin-bottom: 42px;

    @include breakpoint(small only) {
      margin-bottom: 36px;
    }
  }

  &__content {
    margin-bottom: 31px;

    @include breakpoint(small only) {
      margin-bottom: 18px;
    }

    h2 {
      @include h2;
      color: get-color("mid-green");
      margin-bottom: 21px;

      @include breakpoint(small only) {
        margin-bottom: 18px;
      }
    }

    h3 {
      @include subheading();
      color: get-color("mid-green");
      margin-bottom: 21px;

      @include breakpoint(small only) {
        margin-bottom: 18px;
      }
    }

    h2,
    h3 {
      a {
        color: inherit;
      }
    }

    p {
      @include body-copy();
      color: get-color("dark-green");
      margin-bottom: 21px;

      @include breakpoint(small only) {
        margin-bottom: 18px;
      }
    }

    .accommodation__text {
      max-width: 360px;
      width: 100%;

      @include breakpoint(small only) {
        max-width: none;
      }
    }
  }

  &__details {
  }

  &__price {
    text-align: left;

    h4 {
      @include price();
      color: get-color("mid-green");
      margin-bottom: 38px;

      @include breakpoint(small only) {
        margin-bottom: 25px;
      }
    }
  }

  .multiple-buttons {
    margin: 0;
  }
}
