.contact {
  width: 100%;
  max-width: 1344px;
  margin: 0 auto 126px;
  padding: 0 42px;

  @include breakpoint(small only) {
    margin-bottom: 54px;
    padding: 0 18px;
  }

  a {
    color: get-color("mid-green");
  }

  &__details {
    @include breakpoint(small only) {
      padding: 0 20px 41px;
    }

    @include breakpoint(medium) {
      padding-right: 20px;
    }

    @include breakpoint(large) {
      padding-right: 40px;
    }

    h3 {
      @include tertiary-heading();
      margin-bottom: 34px;
      color: get-color("mid-green");
    }

    p {
      @include body-copy();
    }

    a {
      text-decoration: underline;
    }
  }

  &__form {
    background-color: get-color("green-hint");
    padding: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(small only) {
      padding: 18px;
    }

    .form {
      padding-bottom: 63px;

      @include breakpoint(small only) {
        padding-bottom: 84px;
      }

      .grecaptcha-badge {
        bottom: 21px !important;
        right: 21px !important;

        @include breakpoint(small only) {
          right: auto !important;
          left: 18px !important;
          bottom: 18px !important;
        }
      }
    }

    h2 {
      @include tertiary-heading;
      color: get-color("mid-green");
      margin-bottom: 50px;
    }

    .button {
      min-width: 117px;
      margin-bottom: 0;
    }

    input {
      color: get-color("dark-green");
      padding: 0 !important;
    }
  }

  textarea {
    padding-left: 0 !important;
    padding-top: 10px !important;
    border-top: 1px #cfd0c0 solid;
  }

  &__wavy-pattern {
    background-image: url("img/wavy-contact-form.svg");
    height: 100%;
    position: absolute;
    width: 200px;
    top: -12px;
    right: -50px;
    pointer-events: none;
  }

  .umbraco-forms-form {
    h4 {
      display: none;
    }

    fieldset {
      border: none;
      padding: 0;
    }

    label {
      font-family: $primary-font;
    }

    .umbraco-forms-label {
      font-size: 16px;
      line-height: 19px;
    }

    br {
      display: none;
    }

    .shortanswer {
      width: 100%;
      display: inline-block;

      @include breakpoint(small only) {
        width: 99%;
      }
    }

    .shortanswer.address {
      width: 99%;
    }

    .umbraco-forms-field-wrapper {
      margin: 20px 10px;

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="password"],
      input[type="number"],
      textarea {
        width: 100%;
        padding: 0 11px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid get-color("extra-pale-green");
        font-family: $primary-font;
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
        color: get-color("dark-green");
        max-width: 100% !important;

        &::placeholder {
          color: inherit;
        }

        &:active,
        &:focus {
          color: get-color("extra-pale-green");
          border-color: get-color("dark-green");
        }
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="password"],
      input[type="number"] {
        height: 24px;
      }

      textarea {
        padding: 11px;
        height: 205px;
        border-top: 1px solid get-color("extra-pale-green");
        margin-top: 20px;
      }

      label.error {
        color: #a70000;
        display: block;
        margin-bottom: 0px;
        transform: translateY(-15px);
        font-family: $secondary-font-roman;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
      }

      .checkboxlist,
      .radiobuttonlist {
        position: relative;
        text-align: left;

        input[type="radio"] {
          left: 100px;
        }

        input[type="checkbox"],
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          top: 0;
          z-index: 1;

          + label {
            position: relative;
            padding-left: 20px;
            padding-top: 0;
            cursor: pointer;
            display: block;
            font-family: $secondary-font;
            font-size: 10px;
            line-height: 14px;
            color: get-color("dark-green");
            margin-bottom: 20px;

            &::before,
            &::after {
              content: "";
              position: absolute;
            }

            &::before {
              top: 1px;
              left: 0;
              width: 12px;
              height: 12px;
              border: 1px solid;
              border-color: get-color("dark-green");
              background-color: transparent;
            }

            &::after {
              content: "";
              background-color: get-color("dark-green");
              padding: 3px 0 0 1px;
              top: 3px;
              left: 2px;
              width: 8px;
              height: 8px;
              opacity: 0;
              transition: opacity 0.3s ease-in-out;
            }

            a {
              color: inherit;
              transition: opacity 0.3s ease-in-out;

              &:hover,
              &:active {
                text-decoration: underline;
              }
            }
          }

          &:checked + label {
            &:after {
              opacity: 1;
            }
          }
        }
      }

      .field-validation-error {
        color: #a70000;
        display: block;
        margin-bottom: 0px;
        transform: translateY(4px);
        font-family: $secondary-font-roman;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }

    .umbraco-forms-navigation {
      input[type="submit"] {
        background-color: transparent;
        border: none;
        color: #00271e;
        cursor: pointer;
        font-family: Bluu-Next, serif;
        font-size: 15px;
        line-height: 19px;
        overflow: hidden;
        padding: 0 0 10px;
        position: relative;
        transition: all 0.3s ease-in-out;
        float: right;
      }
    }

    .dateofarrival {
      width: 49%;
      display: inline-block;

      @include breakpoint(small only) {
        width: 100%;
      }
      label {
        display: none;
      }
    }

    .radiobuttonlist {
      label {
        font-family: $secondary-font;
        font-size: 10px;
        line-height: 14px;
        margin-right: 20px;
      }
    }

    .diet,
    .allergies,
    .time {
      width: 100%;
    }

    span.umbraco-forms-tooltip {
      font-family: $primary-font;
    }
  }

  &__restaurant-booking {
    width: 100%;

    form {
      align-items: flex-end;
    }

    label {
      font-size: 13px;
      line-height: 19px;
    }

    .selectric-wrapper {
      padding-top: 11px;

      @include breakpoint(small only) {
        padding-top: 9px;
      }
    }

    b.button {
      min-width: 10px;
      img {
        display: none;
      }
    }

    .button {
      margin-bottom: 0;
    }
  }

  &__field-container {
    @include breakpoint(small only) {
      text-align: center;
    }
  }

  .form__submit {
    .button {
      @include breakpoint(small only) {
        min-width: initial;
      }
    }
  }
}
