.meet-the-team {
  &__inner {
    width: 100%;
    max-width: 1344px;
    padding: 0 42px;
    margin: 0 auto 84px;

    @include breakpoint(small only) {
      padding: 25px 0;
      margin-bottom: 54px;
    }
  }

  &__intro {
    padding: 126px 0;

    @include breakpoint(small only) {
      padding: 54px 0;
    }

    &-image {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include breakpoint(small only) {
        padding-right: 54px;
        margin-bottom: 18px;
      }
    }

    &-text {
      position: relative;
      padding-left: 38px;
      padding-top: 63px;
      width: 353px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include breakpoint(small only) {
        max-width: 100%;
        padding: 0 18px;
      }

      &-title {
        padding-bottom: 105px;

        @include breakpoint(small only) {
          padding-bottom: 36px;
        }
      }

      &-main {
      }

      h1 {
        @include super-header;
        color: get-color("mid-green");
        margin-bottom: 0;

        @include breakpoint(small only) {
          position: initial;
        }
      }

      h2 {
        font-family: $primary-font;
        color: get-color("mid-green");
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 21px;
        margin-top: 0;

        @include breakpoint(small only) {
          margin-bottom: 18px;
        }
      }

      p {
        @include body-copy;
        color: get-color("dark-green");
        margin-top: 0;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      h2,
      p {
        @include breakpoint(small only) {
          margin-left: 36px;
        }
      }
    }
  }

  &__grid-items {
    margin: 0 -15px;
  }

  &__grid-item {
    padding: 21px 15px;

    h2 {
      @include tertiary-heading();
      color: get-color("mid-green");
      margin-top: 35px;
    }

    h3 {
      @include subheading();
      color: get-color("mid-green");
      margin-top: 22px;
    }

    p {
      @include body-copy();
      color: get-color("dark-green");
      margin-top: 23px;
    }

    &__cta {
      margin-top: 9px;
    }
  }
}
