.breadcrumb {
    text-align: center;
    padding-top: 21px;

    &__list {
	    display: inline-block;
        text-align: left;
        margin: 0;
        padding: 0 18px 5px 18px;
        list-style-type: none;

        @include breakpoint(small only) {
          text-align: center;
        }

        li {
	        display: inline-block;
	        white-space: nowrap;
            color: get-color('mid-green');
            font-family: $secondary-font-roman;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            &:nth-child(1n+3) {
                &:before {
                    content: '>';
                    padding: 0 4px;
                }
            }

            a {
                transition: 0.3s all ease-in-out;
                color: get-color('dark-green');

                &:hover {
                    color: get-color('mid-green');
                }
            }
        }
    }
}
