.intro {
  width: 100%;
  max-width: 840px;
  margin: 0 auto 42px;
  padding: 42px 21px 21px;
  text-align: center;

  @include breakpoint(small only) {
    padding: 36px 18px 18px;
    margin-bottom: 18px;
  }

  h2 {
    @include page-heading;
    color: get-color("mid-green");
    margin-bottom: 21px;
  }

  h3 {
    @include subheading();
    color: get-color("mid-green");
    max-width: 630px;
    width: 100%;
    margin: 0 auto 21px;
  }

  p {
    @include body-copy;
    color: get-color("dark-green");
    max-width: 630px;
    width: 100%;
    margin: 0 auto 21px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .multiple-buttons {
    padding-top: 21px;
    text-align: center;

    &:empty {
      padding-top: 0;
    }
  }
}
