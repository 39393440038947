.whatsapp {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 100%;
  padding: 0 36px;
  margin: 0 auto;
  z-index: 1;
  display: none;
  text-align: center;

  a {
    img {
      transition: 1s all ease-in-out;
      width: 100%;
      max-width: 300px;
      height: auto;
      opacity: 0;
    }
  }

  &.show {
    display: block;

    img {
      opacity: 1;
    }
  }
}
