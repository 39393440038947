.accordion {
  margin: 80px auto 120px;

  .grid-container {
    max-width: 1260px;
    width: 100%;
    padding: 0px;

    @include breakpoint(small only) {
      padding: 0 18px;
    }
  }

  @include breakpoint(small only) {
    margin: 0 auto 60px;
  }

  &__inner {
    padding: 0 42px;

    @include breakpoint(small only) {
      padding: 0;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
    }

    &-wrap {
      border-bottom: 1px solid get-color("pale-green");
    }
  }

  .accordionButton {
    position: relative;
    border-top: 1px solid #fff;
    padding: 21px 0;
    cursor: pointer;
    border-top: 1px #cfd0bf solid;

    @include breakpoint(small only) {
      padding: 18px 0;
    }

    &::after {
      content: "";
      position: absolute;
      top: 30px;
      right: 10px;
      width: 14px;
      height: 14px;
      background: url("img/accordion-plus.svg") no-repeat;
      background-size: 14px 14px;
      transition: all 0.3s ease-in-out;

      @include breakpoint(small only) {
        right: 10px;
      }
    }

    &.on::after {
      transform: rotate(45deg);
    }

    h3 {
      @include tertiary-heading;
      color: get-color("mid-green");
      margin-bottom: 0;
      padding-right: 21px;

      @include breakpoint(small only) {
        padding-right: 36px;
        font-size: 26px;
        line-height: 30px;
      }
    }
  }

  &__content {
    p {
      @include body-copy();
      color: get-color("dark-green");

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        text-decoration: underline;
        color: inherit;
      }
    }

    .multiple-buttons {
      text-align: left;
    }

    text-align: left;

    ul {
      margin: 0;
      list-style: none;
    }

    li {
      @include body-copy();
      margin-bottom: 10px;
      color: get-color("dark-green");
      padding-left: 12px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 2px;
        background-color: get-color("mid-green");
        transform: translateY(-50%);
      }
    }
  }
}

.accordionContent {
  background-color: get-color("green-hint");
  padding: 32px 26px;
  margin: 0 0 42px;

  @include breakpoint(small only) {
    margin: 0 0 36px 0;
    padding: 27px 18px;
  }

  .accordion__content {
    max-width: 540px;
    width: 100%;

    @include breakpoint(small only) {
      max-width: none;
    }
  }
}
