.twelve-months {
  max-width: 1344px;
  width: 100%;
  margin: 0 auto 126px;
  padding: 0 42px;

  @include breakpoint(small only) {
    margin-bottom: 0;
    padding: 0;
  }

  &__item {
    margin-bottom: 42px;

    @include breakpoint(medium only) {
      margin-bottom: 54px;
    }

    @include breakpoint(small only) {
      margin-bottom: 0;
    }

    &.offset {
      margin-top: -105px;

      @include breakpoint(medium down) {
        margin-top: 0;
        margin-bottom: 54px;
      }

      @include breakpoint(small only) {
        margin-bottom: 0;
      }

      .twelve-months__item {
        &-text {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          @include breakpoint(small only) {
            padding-bottom: 36px;
          }
        }
      }
    }

    &-image {
      padding-right: 42px;

      @include breakpoint(small only) {
        margin-bottom: 18px;
        padding-right: 54px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-text {
      padding-top: 42px;
      padding-bottom: 105px;

      @include breakpoint(small only) {
        padding: 18px 18px 36px;
      }

      h2 {
        @include super-header;
        color: get-color("mid-green");
        margin-bottom: 21px;
        z-index: 1;
        padding-left: 21px;

        @include breakpoint(small only) {
          margin-bottom: 18px;
          padding-left: 0;
        }
      }

      p {
        @include body-copy;
        color: get-color("dark-green");
        margin-bottom: 21px;
        padding-left: 21px;

        @include breakpoint(small only) {
          padding-left: 36px;
        }
      }

      .multiple-buttons {
        margin: 0;
        padding-left: 21px;
        width: 100%;

        @include breakpoint(small only) {
          padding-left: 36px;
        }

        .button {
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    &--right {
      margin-top: 40px;
      .twelve-months__item {
        &-image {
          padding-right: 0;
          padding-left: 54px;
        }

        &-text {
          text-align: right;
          padding-top: 105px;
          padding-bottom: 142px;

          @include breakpoint(small only) {
            text-align: left;
            padding: 18px 18px 36px;
          }

          h2 {
            z-index: 1;
            padding-left: 0;
          }

          p {
            // padding-left: 0;
            // padding-right: 63px;
            padding-left: 21px;
            padding-right: 0;

            @include breakpoint(small only) {
              padding-left: 36px;
            }
          }

          .multiple-buttons {
            // padding-left: 0;
            // padding-right: 63px;
            padding-left: 21px;
            padding-right: 0;
            text-align: right;

            @include breakpoint(small only) {
              text-align: left;
              padding-left: 36px;
            }
          }
        }
      }
    }

    &--landscape {
      padding: 0;
      margin-top: 40px;

      @include breakpoint(small only) {
        padding: 0;
      }

      .twelve-months__item {
        &-image {
          transform: translateX(21px);
          padding-right: 0;

          @include breakpoint(small only) {
            transform: translateX(0px);
            padding: 0;
          }
        }

        &-text {
          transform: translateX(21px);
          padding: 21px 0 21px 63px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          @include breakpoint(small only) {
            padding: 18px 18px 36px;
          }

          h2 {
            z-index: 1;
          }
        }
      }

      &-right {
        padding-top: 42px;

        @include breakpoint(small only) {
          padding: 0;
        }

        .twelve-months__item {
          &-image {
            padding-right: 0;
            transform: translateX(-21px);

            @include breakpoint(small only) {
              transform: translateX(0px);
              padding: 0;
            }
          }

          &-text {
            padding: 21px 63px 21px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: right;

            @include breakpoint(small only) {
              padding: 18px 18px 36px;
              text-align: left;
            }

            h2 {
              z-index: 1;
              padding-left: 0;
            }

            p {
              padding-left: 0;
            }

            .multiple-buttons {
              padding-left: 0;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
