.filter {
  text-align: center;
  margin-bottom: 63px;

  @include breakpoint(small only) {
    padding: 0 20px;
  }

  &__inner {
    background-color: get-color("green-hint");
    padding: 5px 20px;

    @include breakpoint(small only) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  span,
  .offers__filter-buttons,
  &__buttons {
    display: inline-block;
  }

  span {
    @include price;
    line-height: 25px;
    color: get-color("mid-green");
  }

  &__buttons {
    padding-left: 42px;

    @include breakpoint(small only) {
      padding-left: 0;
    }

    .button {
      font-family: $secondary-font !important;
      color: get-color("dark-green") !important;
      font-size: 10px !important;
      line-height: 12px !important;
      font-weight: 400 !important;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-right: 21px;
      padding-top: 4px;
      transition: 0.4s all ease-in-out;
      text-decoration: underline;
      text-underline-offset: 5px;
      text-decoration-color: transparent !important;

      &:hover {
        text-underline-offset: 3px;
        text-decoration-color: get-color("dark-green") !important;
      }

      &.on {
        text-underline-offset: 3px;
        text-decoration-color: get-color("dark-green") !important;
      }
    }
  }

  select {
    border: 0;
    margin-left: 15px;
  }

  .selectric {
    background: transparent;
    text-align: center;
    border: 0;
    margin-left: 50px;

    .label {
      display: inline-block;
      height: auto;
      vertical-align: middle;
      margin: 0 15px 0 0;
      font-size: 10px;
      font-family: $secondary-font;
      font-weight: 400;
      text-transform: uppercase;
      color: black;
    }

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-items {
      width: 200px !important;
      transform: translateX(-40%);
    }

    .button {
      position: initial;
      display: inline-block;
      color: transparent;
      width: 10px;
      height: 8px;
      margin: 0;
      vertical-align: middle;
      background: url("img/chevron-down-black.svg") no-repeat center center;
      text-indent: -12345px;

      &::before,
      &::after {
        content: none;
      }
    }

    &-scroll {
      width: 200px;
      @include bullet-list();
    }
  }
}

.highlighted {
  background-color: get-color("green-hint") !important;
}
