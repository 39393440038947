.quote {
  padding: 15px 0;
  margin: 100px auto;

  @include breakpoint(small only) {
    padding: 36px 0 72px;
    margin-bottom: 0;
    margin-top: 36px;
  }

  &__inner {
    max-width: 1302px;
    width: 100%;
    margin: 0 auto;
    padding: 0 21px;
  }

  &__slider {
    margin: 0 auto;

    &-slide {
      position: relative;
      text-align: center;

      .quote-text {
        @include quote();
        color: get-color("mid-green");
        margin-bottom: 42px;
        // max-width: 589px;
        width: 100%;
        margin: auto;

        @include breakpoint(small only) {
          margin-bottom: 36px;
        }

        .quote-opening,
        .quote-closing {
          font-size: 67px;
          font-weight: bold;
          font-family: $primary-font;
          color: get-color("pale-green");
          display: inline-block;
          line-height: 0;
        }

        .quote-opening {
          transform: translate(-3px, 27px);
        }

        .quote-closing {
          transform: translate(4px, 42px);
        }
      }

      h2,
      h3 {
        font-family: $primary-font;
        font-size: 15px;
        line-height: 24px;
        color: get-color("dark-green");
      }

      h2 {
        margin-top: 42px;
        margin-bottom: 0;
      }

      h3 {
        color: get-color("mid-green");
      }
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      z-index: 50;
      text-indent: -12345px;
      width: 21px;
      height: 21px;
      cursor: pointer;
      transform: translateY(calc(-50% - 32px));

      @include breakpoint(small only) {
        top: auto;
        bottom: -45px;
        transform: translateY(0px);
      }

      &.slick-prev {
        left: -63px;
        background: url("img/carousel-prev.svg") no-repeat;
        width: 21px;
        height: 21px;
        background-size: 27px 21px;
        background-position: left center;
        transition: all 0.3s ease-in-out;

        &:hover,
        &:active {
          width: 27px;
        }

        @include breakpoint(small only) {
          left: calc(50% - 50px);
        }
      }

      &.slick-next {
        right: -63px;
        background: url("img/carousel-next.svg") no-repeat;
        width: 21px;
        height: 21px;
        background-size: 27px 21px;
        background-position: right center;
        transition: all 0.3s ease-in-out;

        &:hover,
        &:active {
          width: 27px;
        }

        @include breakpoint(small only) {
          right: calc(50% - 50px);
        }
      }
    }
  }
}
