.detail {
  text-align: center;
  width: 100%;
  max-width: 1260px;
  margin: 0 auto 116px;

  @include breakpoint(small only) {
    padding: 0 18px;
    margin-bottom: 54px;
  }

  &__inner {
    background-color: get-color("green-hint");
    padding: 52px 21px 52px;
  }

  &__features {
    width: 100%;
    max-width: 660px;
    margin: 0 auto 42px;
    padding: 0 21px;

    @include breakpoint(small only) {
      padding: 0;
      margin: 0 auto 18px;
    }

    h2 {
      @include h2;
      color: get-color("mid-green");
      margin-bottom: 42px;

      @include breakpoint(small only) {
        margin-bottom: 27px;
      }
    }

    &-list {
      text-align: left;

      ul {
        margin: 0;
        list-style: none;
      }

      li {
        @include bullet-list;
        margin-bottom: 0;
        color: get-color("dark-green");
        padding-left: 12px;
        position: relative;
        margin-bottom: 10px;

        &::before {
          content: "";
          position: absolute;
          top: 9.5px;
          left: 0px;
          width: 4px;
          height: 2px;
          background-color: get-color("mid-green");
        }
      }

      &--additional {
        ul {
          li {
            font-weight: 600;

            a {
              color: inherit;
            }
          }
        }
      }
    }
  }

  &__cta {
    padding-top: 7px;

    @include breakpoint(small only) {
      text-align: left;
    }

    .button {
      margin-bottom: 0;
    }
  }

  &__rate {
    @include price;
    color: get-color("mid-green");
    margin-bottom: 42px;

    @include breakpoint(small only) {
      text-align: left;
    }
  }
}
