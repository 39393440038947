.button {
  border: none;
  font-family: $primary-font-bold;
  font-weight: 700;
  color: get-color("dark-green");
  font-size: 15px;
  line-height: 19px;
  padding: 0 0 10px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  img,
  svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 11px;
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    transition: all 0.3s ease-in-out;
  }

  &:hover,
  &:active,
  &.on,
  &:focus {
    color: get-color("mid-green");
    background-color: transparent;

    img,
    svg {
      clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    }
  }

  &.on {
    img {
      object-fit: contain;
    }
  }

  &--white {
    color: #fff;

    img,
    svg {
      path {
        stroke: #fff;
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: get-color("pink") !important;
      background-color: transparent;

      img,
      svg {
        path {
          stroke: get-color("pink");
        }
      }
    }
  }
}

.multiple-buttons {
  text-align: left;
  margin-bottom: -12px;

  @include breakpoint(small only) {
    margin-bottom: 0;
  }

  .button {
    margin: 0 12px 12px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &:empty {
    padding: 0;
  }
}
