.home {
  &__item {
    &-inner {
      position: relative;
    }

    &-image {
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
      }

      &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.18) 66%,
          rgba(0, 0, 0, 0) 100%
        );
        opacity: 0.6;

        @include breakpoint(small only) {
          width: 100%;
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.18) 66%,
            rgba(0, 0, 0, 0.18) 100%
          );
        }
      }
    }

    &-text {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 1344px;
      padding: 0 42px;
      transform: translate(-50%, -50%);
      z-index: 2;

      @include breakpoint(small only) {
        padding: 0 18px;
        transform: translate(-50%, -30%);
      }

      &-inner {
      }

      h3 {
        @include h2;
        color: #fff;
      }

      h4 {
        @include subheading;
        color: #fff;
        margin: 15px 0;
      }

      p {
        @include body-copy;
        color: #fff;
        margin-bottom: 49px;

        @include breakpoint(small only) {
          margin-bottom: 30px;
        }
      }
    }

    &--split {
      background-color: #fff;

      .home__item {
        &-inner {
          max-width: 1260px;
          width: 100%;
          margin: 0 auto;
          padding: 105px 0 126px;

          @include breakpoint(small only) {
            padding: 36px 0 0;
          }
        }

        &-image {
          @include breakpoint(small only) {
            order: 2;
          }

          &-overlay {
            display: none;
          }
        }

        &-text {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          top: auto;
          left: auto;
          transform: translate(0, 0);
          padding-top: 105px;

          @include breakpoint(small only) {
            padding: 0 18px 36px;
            order: 1;
            width: 100%;
          }

          &-title {
            padding-bottom: 105px;

            @include breakpoint(small only) {
              padding-bottom: 9px;
            }
          }

          &-main {
          }

          h3 {
            @include super-header;
            color: get-color("pink");
            margin-bottom: 0;
            transform: translate(0, 0);
            padding-left: 63px;

            @include breakpoint(medium only) {
              padding-left: 42px;
            }

            @include breakpoint(small only) {
              position: initial;
              top: auto;
              left: auto;
              transform: translate(0, 0);
              padding-left: 0;
            }
          }

          h4 {
            @include subheading();
            margin-bottom: 21px;
            color: get-color("mid-green");
            padding-left: 63px;

            @include breakpoint(medium only) {
              padding-left: 42px;
              padding-right: 21px;
            }

            @include breakpoint(small only) {
              padding-left: 0;
            }
          }

          p {
            @include body-copy;
            color: get-color("dark-green");
            padding-left: 63px;
            margin-bottom: 21px;

            @include breakpoint(medium only) {
              padding-left: 42px;
              padding-right: 21px;
            }

            @include breakpoint(small only) {
              padding-left: 0;
            }
          }

          .multiple-buttons {
            padding-left: 63px;

            @include breakpoint(medium only) {
              padding-left: 42px;
              padding-right: 21px;
            }

            @include breakpoint(small only) {
              padding-left: 0;
            }

            a.button {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
