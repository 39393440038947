.social-wall {
  text-align: left;
  padding: 63px 0 84px;

  @include breakpoint(small only) {
    margin: 0 18px;
    padding: 54px 0;
  }

  &__heading {
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint(small only) {
    text-align: center;
  }

  &__feed {
    padding-bottom: 0 !important;
  }

  h3 {
    @include h2;
    margin-bottom: 2px a {
      color: inherit;
    }
  }

  p {
    @include subheading();
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  }
}

.crt-grid-post {
  padding: 0 10px !important;

  @include breakpoint(small only) {
    padding: 0 !important;
  }
}

.crt {
  &-feed {
    max-width: 1070px;
    margin: auto;
  }
}
