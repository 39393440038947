.footer {
  width: 100%;
  background-color: get-color("mid-green");

  @include breakpoint(small only) {
    text-align: center;
  }

  &__newsletter {
    max-width: 1260px;
    margin: 0 auto;
    padding: 42px 0 21px;

    @include breakpoint(medium down) {
      padding: 42px 21px 21px;
    }

    @include breakpoint(small only) {
      padding: 36px 18px 54px;
    }

    &-header {
      display: flex;
      padding-right: 42px;

      @include breakpoint(medium only) {
        padding-right: 21px;
      }

      @include breakpoint(small only) {
        display: block;
        padding-right: 0;
      }
    }

    h2 {
      @include subheading();
      color: #fff;
      display: inline-block;
      padding-right: 42px;
      padding-top: 4px;
      margin-bottom: 0;

      @include breakpoint(medium only) {
        padding-right: 21px;
      }

      @include breakpoint(small only) {
        display: block;
        text-align: center;
        margin-bottom: 18px;
        padding: 0;
      }
    }

    &-email {
      padding-right: 42px;

      @include breakpoint(medium only) {
        padding-right: 21px;
      }

      @include breakpoint(small only) {
        padding-right: 0;
      }
    }

    .field {
      display: inline-block;
      flex-grow: 1;

      @include breakpoint(small only) {
        display: block;
        margin-bottom: 36px;
      }
    }

    input[type="text"],
    input[type="email"] {
      width: 100%;
      height: 33px;
      margin-bottom: 0;
      border-bottom: 1px solid get-color("dark-green");
      font-size: 20px;

      &::placeholder {
        color: inherit;
      }

      &:active,
      &:focus {
        color: get-color("extra-mid-green");
        border-color: #fff;
      }
    }

    button[type="submit"],
    input[type="submit"] {
      border: solid 1px get-color("dark-green");
      background-color: get-color("dark-green");
      color: #fff;
      font-family: $primary-font;
      font-size: 15px;
      line-height: 1;
      font-weight: 700;
      padding: 12px 16px 13px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: get-color("orange");
        color: get-color("dark-green");
        border-color: #fff;
      }

      @include breakpoint(small only) {
        display: block;
        margin: 0 auto 18px;
      }
    }

    .form__checkbox {
      @include breakpoint(small only) {
        display: inline-block;
      }
    }
  }

  &__main {
    background: url("img/footer-contour-lines.svg") no-repeat;
    background-size: cover;
    background-color: get-color("dark-green");
    text-align: center;

    @media screen and (max-width: $offcanvas-breakpoint) {
      padding-bottom: 120px;
    }

    @include breakpoint(small only) {
      background: url("img/footer-contour-lines-mobile.svg") no-repeat;
      background-size: cover;
      background-color: get-color("dark-green");
    }
  }

  &__inner {
    position: relative;
    max-width: 1260px;
    margin: 0 auto;
    padding: 42px 21px;
  }

  &__logo {
    margin-bottom: 32px;

    img {
      max-width: 133px;
      width: 100%;
      height: auto;
    }
  }

  &__social {
    list-style-type: none;
    margin-bottom: 32px;

    li {
      display: inline-block;
      margin: 0 10px;
    }

    a {
      img,
      svg {
        width: auto;
        height: 17px;

        path,
        circle {
          fill: get-color("mid-green");
          transition: 0.3s all ease-in-out;
        }
      }

      &:hover {
        svg {
          path,
          circle {
            fill: get-color("orange");
          }
        }
      }
    }

    @include breakpoint(small only) {
      margin-left: 0;
    }
  }

  &__primary {
    margin-bottom: 32px;

    ul {
      list-style-type: none;
      margin: 0;

      @include breakpoint(small only) {
        margin: 0 18px 0 0;
      }
    }

    li {
      display: inline-block;
      margin: 0 21px;

      @include breakpoint(small only) {
        margin: 0 0 4px;
        line-height: 1;
      }
    }

    a {
      position: relative;
      color: get-color("white");
      font-family: $secondary-font-roman;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: get-color("orange");
      }
    }

    @include breakpoint(small only) {
      text-align: left;

      li {
        display: block;
      }
    }
  }

  &__copyright {
    p {
      color: get-color("white");
      font-family: $secondary-font-roman;
      font-size: 10px;
      line-height: 14px;
      margin-bottom: 0;

      @include breakpoint(small only) {
        margin-bottom: 12px;
      }

      &:last-of-type {
        @include breakpoint(small only) {
          margin-bottom: 0;
        }
      }

      a {
        color: inherit;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: get-color("orange");
        }
      }
    }

    @include breakpoint(small only) {
      text-align: left;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__eighty-days {
    position: absolute;
    bottom: -2px;
    right: 21px;

    a {
      font-family: $secondary-font-roman;
      font-size: 10px;
      line-height: 14px;
      color: #8f9974;

      &:hover {
        text-decoration: underline;
      }
    }

    @include breakpoint(small only) {
      position: static;
      text-align: left;
      padding-top: 40px;
    }
  }
}
