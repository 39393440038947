.gallery {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 120px;
	padding: 0 10px;

	@include breakpoint(large) {
		padding: 0 30px;
	}

	&__grid {}

	&__item {
		width: 33%;
		float: left;
		padding: 10px;

		@include breakpoint(medium only) {
			width: 50%;
		}

		@include breakpoint(small only) {
			width: 100%;
		}

		&-sizer {
			width: 33%;
			padding: 10px;

			@include breakpoint(medium only) {
				width: 50%;
			}

			@include breakpoint(small only) {
				width: 100%;
			}
		}

		a {
			position: relative;
			display: block;

			&::before {
				content: '';
				position: absolute;
				z-index: 5;
				bottom: 6px;
				right: 0;
				width: 14px;
				height: 14px;
				background: url('img/accordion-plus.svg') no-repeat center center;
				background-size: 14px 14px;
			}

			img {
				margin-bottom: 10px;
			}

			p {
				@include price();
				margin-top: 10px;
				color: get-color('mid-green')
			}
		}
	}

	&__modal {
		padding: 0;
		top: 0 !important;
		background-color: get-color('dark-green');
		color: white;

		&-inner {
			position: relative;
			width: 83.3333%;
			margin: 0 auto;
			padding: 70px 0;

			@include breakpoint(large) {
				padding: 120px 0;
			}
		}

		&-close {
			position: absolute;
			z-index: 10;
			top: 35px;
			right: 0;
			width: 20px;
			height: 20px;
			text-indent: -12345px;
			cursor: pointer;
			background: url('img/close-white.svg') center center no-repeat;
			background-size: 20px 20px;

			@include breakpoint(large) {
				top: 60px;
			}
		}

		&-image {
			position: relative;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-prev,
		&-next {
			position: absolute;
			top: 50%;
			margin-top: -8px;
			width: 21px;
			height: 21px;
			text-indent: -12345px;
			cursor: pointer;
			background: no-repeat;
			background-size: 27px 21px;
			transition: all 0.3s ease-in-out;

			&:hover,
			&:active {
				width: 27px;
			}
		}

		&-prev {
			left: -30px;
			background-image: url('img/arrow-white-prev.svg');
			background-position: left center;

			@include breakpoint(large) {
				left: -36px;
			}
		}

		&-next {
			right: -30px;
			background-image: url('img/arrow-white-next.svg');
			background-position: right center;

			@include breakpoint(large) {
				right: -36px;
			}
		}

		&-content {
			padding: 20px 0 0;
		}

		&-caption {
			vertical-align: top;
			padding-top: 8px;
			display: block;
			align-items: center;
			@include subheading();

			@include breakpoint(small only) {
				text-align: left;
				margin-bottom: 15px;
			}
		}

		&-cta {
			text-align: center;
			@include price();

			a {
				margin-bottom: 0;

				@include breakpoint(small only) {
					display: block;
					text-align: left;
					margin-top: 20px !important;
				}
			}

			@include breakpoint(small only) {
				text-align: left;
			}

			@include breakpoint(medium) {
				text-align: right;
			}

			.button {
				margin-top: 9px;
				margin-right: 0;

				@include breakpoint(medium) {
				margin-left: 30px;
				}
				color: white;
			}
		}

		&-iframe {
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			padding-top: 25px;
			height: 0;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

	}
}
