.popup {
	@at-root .reveal-overlay {
		z-index: 60000;
	}

	&.reveal {
		border: 0;
		padding: 0;
		overflow: visible;
		padding: 63px 0;

		@include breakpoint(medium down) {
			padding: 54px 18px;
		}

		&:focus {
			outline: none;
		}
	}

	&__inner {
		position: relative;
		display: grid;
		grid-template-columns: repeat(20, 1fr);
		grid-template-rows: 42px 1fr 42px;
		grid-column-gap: 0px;
		grid-row-gap: 0px;

		@include breakpoint(small only) {
			display: block;
		}
	}

	&__close {
		position: absolute;
		top: 0px;
		right: 0px;
		width: 27px;
		height: 27px;
		background: url('img/close-white.svg') no-repeat center center;
		background-size: 27px 27px;
		text-indent: -12345px;
		cursor: pointer;

		@include breakpoint(small only) {
			top: -36px;
			right: 0;
		}
	}

	&__image {
		grid-area: 1 / 2 / 4 / 12;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__text {
		grid-area: 2 / 10 / 3 / 20;
		background-color: get-color('pale-green');
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 42px;

		@include breakpoint(small only) {
			margin: -36px 18px;
			position: relative;
			padding: 36px 18px;
		}


		h2 {
			font-family: $primary-font;
			color: get-color('mid-green');
			font-size: 30px;
			line-height: 36px;
			font-weight: 700;
			margin-bottom: 21px;
		}

		h3 {
			@include subheading;
			color: get-color('mid-green');
			margin-bottom: 21px;
		}

		p {
			@include body-copy();
			color: get-color('dark-green');
			margin-bottom: 32px;
		}

		.multiple-buttons {

		}
	}
}
