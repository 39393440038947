.split-intro {
  width: 100%;
  padding: 168px 0 147px;

  @include breakpoint(small only) {
    padding: 54px 0 0;
  }

  &__inner {
  }

  &__image {
    padding-left: 4%;

    @include breakpoint(small only) {
      order: 2;
      padding-left: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    position: relative;
    padding: 105px 4% 0 42px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(small only) {
      order: 1;
      padding: 0 18px 36px;
      text-align: center;
    }

    &-title {
      padding-bottom: 84px;

      @include breakpoint(small only) {
        padding-bottom: 36px;
      }
    }

    &-main {
    }

    h2 {
      @include super-header;
      color: get-color("mid-green");
      margin-bottom: 0;
      left: 0;
    }

    h3 {
      @include subheading();
      margin-bottom: 21px;
      color: get-color("mid-green");
    }

    p {
      @include body-copy;
      color: get-color("dark-green");
    }

    .multiple-buttons {
      @include breakpoint(small only) {
        text-align: center;
      }

      a.button {
        margin-bottom: 0;
      }
    }
  }
}
